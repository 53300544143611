import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'


// Base language of the site
//const GATSBY_BASE_LANGUAGE = process.env.GATSBY_BASE_LANGUAGE

// Helper function
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

// Language Keys for reference
const languageShorts = {
  'en_US' : 'en',
  'es_ES' : 'es',
  'pl_PL' : 'pl',
  'fr_FR' : 'fr',
  'nl_NL' : 'nl',
  'de_DE' : 'de',
  'it_IT' : 'it',
  'pt_BR' : 'pt',
  'ru_RU' : 'ru',
  'sv_SE' : 'sv',
}

function NotFoundPage(props) {
  const [missingLanguage, setMissingLanguage] = useState(false)

  
  useEffect(() => {
    if(typeof window !== 'undefined') {
      // Find out URL language
      const pathname = window.location.pathname
      const pathnameFragmented = pathname.split('/')
      const urlLangKey = pathnameFragmented[1] // urllangvalue
      //const urlLangKeyFinal = urlLangKey && getKeyByValue(languageShorts,urlLangKey) ? getKeyByValue(languageShorts,urlLangKey) : GATSBY_BASE_LANGUAGE

      // If the first fragment of url is recognized as a language
      if (urlLangKey && getKeyByValue(languageShorts,urlLangKey)) {
        pathnameFragmented.splice(1,1)
      }

      // Find out localstorage language from previous session or user manual choice
      const localLanguageFinal = localStorage.getItem('language') !== 'undefined' && typeof localStorage.getItem('language') !== 'undefined' ? JSON.parse(localStorage.getItem('language')) : null
    
      // Is this 404 for missing language?
      if (localLanguageFinal === getKeyByValue(languageShorts,urlLangKey)) {
        setMissingLanguage(`${pathnameFragmented.join('/')}`)
      }
    }
  }, [])
  

  return (
    <section id="404-page" className='c5 text-center flex-12 gap-075 ai-center jc-center' style={{minHeight: '90vh'}}>
      <div className="span-12 span-12-mobile padd-2 text-center">
        <h1>NOT FOUND</h1>
        {missingLanguage ? <h4>Sorry, this page hasn't been translated yet, <br/><Link to={missingLanguage}>try english version of this page.</Link></h4> : null}
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </section>
  )
}

export default NotFoundPage